import React from "react";
// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components

function Tokenomics() {
  return (
    <>
      <div
          className="features-5 section-image section-dark"
          id="tokenomics"
        >
          <Container>
            <Row>
              <div className="ml-auto mr-auto">
                <h2 className="title text-center">
                  $VEND Tokenomics
                </h2>
              </div>
            </Row>
            <Row>
              <Col className="ml-auto" sm="5">
                <div className="info">
                    <div className="icon">
                      15.5 M
                    </div>
                    <h4 className="title">Airdrops</h4>
                    <p>
                      15.5M $VEND distributed to Core Supporter NFT holders and random active community members
                    </p>
                  </div>
              </Col>
              <Col className="mr-auto" sm="5">
              <div className="info">
                    <div className="icon">
                      5%
                    </div>
                    <h4 className="title">Initial Distribution</h4>
                    <p>
                      30M $VEND for <a href="https://crunchy.network/#/ifo/Vendcoin">Crunchy IFO</a>
                      <br/>
                      Up to 23M $VEND for Initial LP & Airdrops
                    </p>
                  </div>
              </Col>
            </Row>
            <Row >
              <Col className="ml-auto" sm="5">
              <div className="info">
                    <div className="icon">
                      10%
                    </div>
                    <h4 className="title">Community Rewards</h4>
                    <p>
                      100M $VEND set aside to reward active users for providing LP, staking $VEND & Plenty Bribes
                    </p>
                  </div>

              </Col>
              <Col className="mr-auto" sm="5">
              <div className="info">
                  <div className="icon">
                    25%
                  </div>
                  <h4 className="title">LP Incentives</h4>
                  <p>
                    247M $VEND held in reserve to provide additional liquidity and stabilize price volatility after IFO.
                  </p>
                </div>
              </Col>
            </Row>
           
            <Row >
              <Col className="ml-auto" sm="5">
                <div className="info">
                  <div className="icon">
                    10%
                  </div>
                  <h4 className="title">Project Development</h4>
                  <p>
                    100M $VEND allocated for 100% transparent Project Development and Team Funding
                  </p>
                </div>
              </Col>
              <Col className="mr-auto" sm="5">
                <div className="info">
                    <div className="icon">
                      50%
                    </div>
                    <h4 className="title">LP Reserves</h4>
                    <p>
                      500M $VEND set aside for future LP initiatives locked for a 1 year period.
                    </p>
                  </div>
              </Col>
            </Row>
            
          </Container>
        </div>  
    </>
  );
}

export default Tokenomics;
