import React from "react";
// reactstrap components
import { Container } from "reactstrap";

// core components

function Disclaimer() {
  return (
    <>
      <div
          className="features-5 section-image section-dark"
          id="disclaimer"
        >
          <Container>
          
          <p>
          <sub><b>LEGAL DISCLAIMER:</b></sub></p>
<p><sub>Please read this disclaimer carefully before engaging with the $VEND token from the Tezos Vending Machine. By purchasing or 
otherwise acquiring the token, you agree to be bound by the terms and conditions outlined in this disclaimer. If you do not agree 
with any part of this disclaimer, do not acquire or use the token.
</sub></p>
<p><sub>1. Token Purpose:
The token offered or sold by is intended solely for use within its specific ecosystem, network, or platform. The token is not designed, 
marketed, or intended as a speculative investment or financial instrument. Purchasing or acquiring the token should not be done 
with the expectation or intention of making a profit or gaining financial returns.
</sub></p>
<p><sub>
2. No Representations or Warranties:
The Vending Machine Project does not make any promises, guarantees, or representations regarding the future value, price, or 
performance of the token. The acquisition of the token does not entitle the holder to any ownership rights, dividends, interests, or 
other financial benefits typically associated with the ownership of securities or investment vehicles.
</sub></p>
<p><sub>
3. Risks and Volatility:
The token market is highly volatile, and its value may fluctuate significantly. It is important to recognize and accept the associated 
risks involved in acquiring or using the token. Past performance does not guarantee future results, and the Vending Machine Project 
is not liable or responsible for any loss or damages incurred as a result of token value depreciations, market instability, or any other 
factors beyond our control.
</sub></p>
<p><sub>
4. Regulatory Compliance:
The token's legal status and regulatory framework may vary depending on the jurisdiction. It is the responsibility of the purchaser to 
ensure compliance with applicable laws, regulations, or restrictions in their respective jurisdiction concerning token acquisition, 
usage, or any related activities.
</sub></p>
<p><sub>
5. Independent Research and Due Diligence:
Prior to acquiring the token, it is strongly recommended that purchasers undertake their own independent research and due 
diligence. This may include seeking advice from legal, financial, or tax professionals to assess if the acquisition aligns with their 
personal situation, investment objectives, and risk tolerance.
</sub></p>
<p><sub>
6. Amendments and Updates:
The Vending Machine Project reserves the right to amend, update, or modify this disclaimer at any time without prior notice. It is 
the purchaser's obligation to review and stay updated on the latest version of this disclaimer to ensure continued compliance.
</sub></p>
<p><sub>
7. No Liability:
To the fullest extent permitted by law, The Vending Machine Project, its officers, employees, agents, affiliates, and partners shall not 
be held liable for any direct, indirect, incidental, consequential, or exemplary damages or losses arising from the purchase, 
possession, or use of the token.
</sub></p>
<p><sub>
By engaging with $VEND and the Vendcoin token project, you acknowledge and agree to the terms stated in this disclaimer. If you do not 
agree, do not participate or acquire the token.
</sub></p>

        </Container>
      </div>  
    </>
  );
}

export default Disclaimer;
