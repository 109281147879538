import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

// core components

function IFO() {
  return (
    <>
      <div className="section section-sharing section-dark" id="IFO">
        <Container>
          <Row>
            <div className="title text-center">
              <h2 className="title">Initial Farm Offering (IFO)</h2>
              <p className="description">
                An <a href="https://crunchytez.medium.com/introducing-initial-farm-offerings-ifos-731f42392b0e" target="_blank" rel="noreferrer" id="whatisIFO">
                    <font color="white">
                      Initial Farm Offering (IFO)
                    </font>
                  </a>
                  <UncontrolledTooltip placement="bottom" target="whatisIFO">
              Click to learn more...
            </UncontrolledTooltip> will take place on January 5<sup>th</sup>, hosted by Crunchy. 
                <br/>30,000,000 $VEND will be offered at a 20% discount rate.
                <br/><br/>The sale period will last for <b>10 days</b>, followed by a <b>10 day lock period</b>; 
                <br/>after which the <b>90 day farming period</b> will begin.
              </p>
              <br />
            </div>
            <Col className="ml-auto mr-auto" md="8">
              <div className="space-top" />
              <Row>
                <Col md="6">
                  <Card data-background="color" data-color="green" className="card-pricing">
                    
                    <CardBody>
                      <CardTitle tag="h3">IFO Offering<br />.000133 ꜩ</CardTitle>
                      <p className="card-description">
                      </p>
                      <CardFooter>
                        <Button
                          className="btn-round"
                          target="_blank"
                          color="info"
                          href="https://crunchy.network/#/ifo/Vendcoin"
                        >
                          Farm Now
                        </Button>
                      </CardFooter>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="6">
                  <Card data-background="color" data-color="orange" className="card-pricing">
                    <CardBody>
                     
                      <CardTitle tag="h3">PUBLIC Offering<br />.000166 ꜩ</CardTitle>
                      <p className="card-description">
                      </p>
                      <CardFooter>
                        <Button
                          className="btn-round"
                          target="_blank"
                          color="danger"
                          >
                          Buy Later
                        </Button>
                      </CardFooter>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col className="offset-md-2 text-center" md="8" />
          </Row>
          
        </Container>
      </div>
    </>
  );
}

export default IFO;
