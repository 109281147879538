import React from "react";
// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";

// core components

function About() {
  return (

      <div className="section section-examples section-dark" id="about">
      
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title">Why $VEND?</h2>
                <h5 className="description">
                As a $VEND token holder, you contribute to the growth of the Tezos De-Fi + Art ecosystem, embracing the spirit of community and the arts on Tezos.
                In addition to supporting the VendingNFTs project, holding $VEND also grants exciting & valuable utilities.
                </h5>
              </Col>
            </Row>
            <Row>
            <Col md="3">
                <div className="info">
                  <div className="icon icon-danger">
                    <i className="nc-icon nc-diamond" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Exclusive Access</h4>
                    <p>
                      VIP status during VendingNFT events and early access to Special Events, Machine Takeovers, and more!
                    </p>
                   
                  </div>
                </div>
              </Col>
              <Col md="3">
                <div className="info">
                  <div className="icon icon-danger">
                    <i className="nc-icon nc-spaceship" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Machine Discounts</h4>
                    <p>
                      Save some coin! Using $VEND will (in most cases) be a better value than using $XTZ at The Vending Machine. 
                    </p>
                   
                  </div>
                </div>
              </Col>
              <Col md="3">
                <div className="info">
                  <div className="icon icon-danger">
                    <i className="nc-icon nc-palette" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Artist Support</h4>
                    <p className="description">
                      Use $VEND to randomly dispense art from the Vending Machine, and help support new artists with every pull!
                    </p>
                    
                  </div>
                </div>
              </Col>
              <Col md="3">
                <div className="info">
                  <div className="icon icon-danger">
                    <i className="nc-icon nc-trophy" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">De-Fi Rewards</h4>
                    <p>
                      Participate in a number of Vendcoin De-Fi Reward initiatives, including LP Rewards, Staking, and more! 
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
  );
}

export default About;
