/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container } from "reactstrap";
// core comments

function PresentationHeader() {
  return (

      <div className="wrapper">
        <div
          className="page-header section-dark"
          style={{
            backgroundImage:
              "url(" + require("assets/img/sections/pk-pro-cover.jpg") + ")",
          }}
        >
          <div className="content-center">
            <Container>
              <div className="title-brand">
              <h1 className="presentation-title">$VEND</h1>
                  <div className="type">
                    IFO LIVE
                  </div>
                  <div className="fog-low">
                    <img
                      alt="..."
                      src={require("assets/img/sections/fog-low.png")}
                    />
                  </div>
                  <div className="fog-low right">
                    <img
                      alt="..."
                      src={require("assets/img/sections/fog-low.png")}
                    />
                  </div>
              </div>
              
                <h2 className="presentation-subtitle text-center">
                A New Chapter for the NFT Vending Machine Project
                </h2>
            </Container>
            
            
          </div>
          <h6 className="category category-absolute">
          $VEND - The art utility and trading token for the 
          NFT Vending Machine that provides exclusive discounts on 
          machine use, access to exclusive events, and more.
          </h6>
        </div>
      </div>

  );
}

export default PresentationHeader;
